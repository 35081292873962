import React, { useState } from "react";
import FooterDecor from "../images/wave.svg";
import Expander from "../images/snip.png";
import Contracter from "../images/snipflipped.png";
import MediaQuery from "react-responsive";
import { Fragment } from "react";
const Footer = () => {
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);
  return (
    <Fragment>
      <MediaQuery minWidth={1224}>
        <footer className='Footer'>
          <div className='FooterDecor'>
            <img src={FooterDecor} alt='Footerdecor' />
          </div>
          <div className='FooterContent'>
            <ul className='FooterLeftside'>
              <li>
                <h4>Om tjenesten</h4>

                <p>
                  Laksebørsen er et verktøy for deg som vil følge med på
                  fangstrapporter fra lakseelver i Norge. Her kan du se fangster
                  fortløpende ettersom de meldes inn av fiskerne.
                </p>
                <br />
                <p>
                  © 2025 Inatur Norge AS. Data kan ikke benyttes uten tillatelse.
                </p>
              </li>
              <li>
                <hr />
              </li>
              <li>
                <h4>Kontakt</h4>
                <p>
                  Du finner oss på{" "}
                  <a href='mailto:post@inatur.no'>post@inatur.no</a>, telefon
                  74213090 eller Inatur Norge, Postboks 63, sentrum, 7801
                  Namsos.
                </p>
              </li>
            </ul>

            <ul className='FooterRightside'>
              <li>
                <a href={"https://www.inatur.no/om/inatur"} target='__blank'>
                  Om Inatur
                </a>
              </li>
              <li>
                <a
                  href={"https://www.inatur.no/om/aktivering"}
                  target='__blank'
                >
                  Aktiver fiskekort{" "}
                </a>
              </li>
              {/* <li>Samarbeidspartnere</li>  */}
              <li>
                <a
                  href={"https://www.inatur.no/cookies-og-personvern"}
                  target='__blank'
                >
                  Cookies & Personvern
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <footer className='Footer FooterMobile'>
          <div className='FooterDecor'>
            <img src={FooterDecor} alt='Footerdecor' />
          </div>
          <div className='FooterContent'>
            <ul className='FooterList'>
              <li>
                <span className='footerExpander'>
                  <h4>
                    Om tjenesten
                    {showAbout === false ? (
                      <img
                        onClick={() => setShowAbout(!showAbout)}
                        src={Expander}
                        alt='expandicon'
                      ></img>
                    ) : (
                      <img
                        onClick={() => setShowAbout(!showAbout)}
                        src={Contracter}
                        alt='contracticon'
                      ></img>
                    )}
                  </h4>
                </span>
                {showAbout && (
                  <>
                  <p>
                    Laksebørsen er et verktøy for deg som vil følge med på
                    fangstrapporter fra lakseelver i Norge. Her kan du se
                    fangster fortløpende ettersom de meldes inn av fiskerne.
                  </p>
                  <br />
                  <p>
                    © 2025 Inatur Norge AS. Data kan ikke benyttes uten tillatelse.
                  </p>
                  </>
                )}
              </li>
              <li>
                <span className='footerExpander'>
                  <h4>
                    Kontakt
                    {showContact === false ? (
                      <img
                        src={Expander}
                        onClick={() => setShowContact(!showContact)}
                        alt='expandicon'
                      ></img>
                    ) : (
                      <img
                        src={Contracter}
                        onClick={() => setShowContact(!showContact)}
                        alt='contracticon'
                      ></img>
                    )}
                  </h4>
                </span>
                {showContact && (
                  <p>
                    Du finner oss på{" "}
                    <a href='mailto:post@inatur.no'>post@inatur.no</a>, telefon
                    74213090 eller Inatur Norge, Postboks 63, sentrum, 7801
                    Namsos.
                  </p>
                )}
              </li>
              <li>
                <span className='footerLink'>
                  <h4>
                    <a
                      href={"https://www.inatur.no/om/inatur"}
                      target='__blank'
                    >
                      Om Inatur
                    </a>
                  </h4>
                </span>
              </li>
              <li>
                <span className='footerLink'>
                  <h4>
                    <a
                      href={"https://www.inatur.no/om/aktivering"}
                      target='__blank'
                    >
                      Aktiver fiskekort
                    </a>
                  </h4>
                </span>
              </li>
              {/* <li>
                <span className='footerLink'>
                  <h4>
                    <li>Samarbeidspartnere</li>
                  </h4>
                </span>
              </li> */}
              <li>
                <span className='footerLink'>
                  <h4>
                    <a
                      href={"https://www.inatur.no/cookies-og-personvern"}
                      target='__blank'
                    >
                      Cookies & Personvern
                    </a>
                  </h4>
                </span>
              </li>
            </ul>
          </div>
        </footer>
      </MediaQuery>
    </Fragment>
  );
};

export default Footer;
